import type { AvailableRouterMethod, NitroFetchRequest } from 'nitropack'
import type { FetchResult, UseFetchOptions } from 'nuxt/app'
import { LANGUAGE_HEADER } from '@@/server/utils/i18n'

type KeysOf<T> = Array<T extends T ? (keyof T extends string ? keyof T : never) : never>

/**
 * Fetch data from the server and cache it in the payload.
 */
export function useCachedFetch<
   ResT = void,
   ReqT extends NitroFetchRequest = NitroFetchRequest,
   Method extends AvailableRouterMethod<ReqT> = ResT extends void
      ? 'get' extends AvailableRouterMethod<ReqT>
         ? 'get'
         : AvailableRouterMethod<ReqT>
      : AvailableRouterMethod<ReqT>,
   _ResT = ResT extends void ? FetchResult<ReqT, Method> : ResT,
   DataT = _ResT,
   PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
   DefaultT = null,
>(

   request: Ref<ReqT> | ReqT | (() => ReqT),
   options: UseFetchOptions<_ResT, DataT, PickKeys, DefaultT, ReqT, Method> & { shouldWatchLocale?: boolean } = {},
) {
   const { locale } = useI18n()

   const getKey = () => {
      if (options.shouldWatchLocale) {
         return `${options.key}-${toValue(locale)}`
      }
      return options.key ?? ''
   }

   const getHeaders = () => {
      if (options.shouldWatchLocale) {
         return { [LANGUAGE_HEADER]: toValue(locale) }
      }
   }

   return useFetch(request, {
      ...options,
      dedupe: 'defer',
      deep: false,
      getCachedData: (_, nuxtApp) => nuxtApp.payload.data[getKey()] ?? nuxtApp.static.data[getKey()],
      headers: getHeaders(),
      key: getKey(),
   })
}
